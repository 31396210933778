import React from "react"

import Layout from "../components/Layout"

// @ts-ignore
import success from "../images/success.svg"

const Success = () => (
  <Layout title="Success">
    <div className="max-w-xl mx-auto text-center my-24 p-10">
      <h1 className="text-4xl">Formulier verzonden</h1>
      <p className="lead">We nemen spoedig contact met u op.</p>
      <img src={success} className="mt-16" />
    </div>
  </Layout>
)

export default Success
